<template>
  <div class="dealDetail">
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a @click="prev">交易市场</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>商品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <div class="dealDetail_top">
        <el-row>
          <el-col :span="8">
            <div class="ddt_imgBox">
              <div class="ddt_ib_showImg">
                <img :src="showImg" alt />
              </div>
              <div class="ddt_ib_btn">
                <ul class="ddt_ib_btnBox">
                  <li class="ddt_ib_btnBlock" v-for="(item,index) in detailList.imgs" :key="index">
                    <img :src="item" alt @click="changeImg(item)" />
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="16">
            <el-row class="ddt_goodsName">{{detailList.title}}</el-row>
            <el-row class="ddt_blueBox">
              <el-row class="ddt_blueBoxRow">
                <span class="ddtb_title">
                  <span class="ddtb_jia">价</span>格:
                </span>
                <span class="ddtb_price">
                  ￥
                  <span class="ddtb_bigfont">{{detailList.amount}}</span>
                  元
                </span>
              </el-row>
            </el-row>
            <el-row
              class="ddt_defaultRow"
            >所在地区：{{detailList.province}}{{detailList.city}}{{detailList.district}}</el-row>
            <el-row class="ddt_defaultRow">冷链车类型：{{detailList.vehicleType}}</el-row>
            <el-row class="ddt_defaultRow">联系人：{{detailList.name}}</el-row>
            <el-row class="ddt_defaultRow">联系电话：{{hiddenNumber}}</el-row>
            <el-row class="ddt_BtnBox">
              <div class="ddt_phoneBtn" @click="toApp">打电话</div>
              <div class="ddt_buyBtn" @click="toApp">立即租用</div>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="dealDetail_bottom clearfix">
        <div class="ddb_right">
          <!-- <el-row>
            <div class="ddb_right_top">商品详情</div>
          </el-row> -->
          <div class="ddb_right_main">
            <div class="gmr_top">描述介绍</div>
            <!-- <el-row class="ddbr_block_title">商品属性</el-row> -->
            <div class="ddbr_block_borderBox">
              <p>{{detailList.description}}</p>
            </div>
            <div class="gmr_top">优质推荐</div>
            <div class="goodsBlock" v-for="(item,index) in randomlist" :key="index">
              <div class="goodsBlockInside" @click="toDealDetail(item.id)">
                <div class="marketImgBox">
                  <img :src="item.imgs[0]" alt />
                </div>
                <div class="marketfont">
                  <div class="market_Price" v-if="(item.price != null)">¥{{item.price}}</div>
                  <div class="market_Price" v-else>价格面议</div>
                  <div class="market_account">{{item.title}}</div>
                  <div class="market_place">发货地：{{item.province}}</div>
                  <div class="market_seller">{{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/market/market.css";
export default {
  data() {
    return {
      id: this.$route.query.id,
      detailList: [],
      specification: [],
      updataTime: "",
      showImg: "",
      price: "",
      btnShow: "",
      randomlist: []
    };
  },
  computed: {
    hiddenNumber() {
      return (
        this.detailList.phone &&
        this.detailList.phone.replace(/(\d{3})\d{5}(\d{3})/, "$1*****$2")
      );
    }
  },
  mounted() {
    this.marketDetailList();
    this.marketRandomList();
  },
  methods: {
    prev() {
      window.history.go(-1);
    },
    toApp() {
      this.$router.push({
        path: "/downLoad"
      });
    },
    // 优质推荐跳转
    toDealDetail(id) {
      this.$router.push({
        path: "/coldChainCarDetails",
        query: {
          id
        }
      });
    },
    changeImg(num) {
      this.showImg = num;
    },
    timestampToTime(val) {
      const date = new Date(parseInt(val)), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ";
      return Y + M + D;
    },
    async marketDetailList() {
      const api = this.$fetchApi.coldChainCarDetail.api + this.id;
      const data = await this.$fetchData.fetchGet({id: this.id}, api, "json");
      if (data.code === "200" && data.result) {
        this.detailList = data.result;
        this.updataTime = this.timestampToTime(data.timestamp);
        this.showImg = this.detailList.imgs[0];
        this.specification = this.detailList.specification;
        if (this.detailList.specification != null) {
          this.price = this.detailList.specification[0].value;
        } else {
          this.price = "价格面议";
        }
      }
    },
     // 精品推荐
    async marketRandomList() {
      const api = this.$fetchApi.coldChainCarList.api;
      const data = await this.$fetchData.fetchPost(
        {
          city: '', // 市
          district: '', // 区
          pageNum: 1,
          pageSize: 4,
          priceOrder: 0, // 价格排序 0:不限 1:小于1000 2:1000~5000 3:5001~10000 4:10000以上
          province: '', // 省
          recommend: true, // 推荐
          transferType: 0 // 交易类型 1:出租 2:求租
        },
        api,
        "json"
      );
      if (data.code === "200" && data.result) {
        this.randomlist = data.result.rows;
      }
    },
    changePrice(val) {
      this.price = val;
    }
  }
};
</script>
<style scoped>
</style>